@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

html{
  font-family: Poppins;
}

body{
  /* background-color: rgb(217, 217, 217); */

  background-image: url("assets/innova/img/background.png");
  background-size: contain;
}