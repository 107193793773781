.hoverSpan li span {
  height: 50px;
  width: 0%;

  top: 0;
  left: 0;
  position: absolute;

  background-color: #0d131b;

  clip-path: polygon(100% 0%, 100% 10%, 50% 100%, 0% 100%, 0 80%, 0% 0%);

  transition: all ease-in-out 0.8s;

  z-index: -1;
}

.hoverSpan li:hover .spanToRight {
  width: 100%;

  transition: all ease-in-out 0.8s;

  clip-path: polygon(100% 0%, 100% 10%, 100% 100%, 0% 100%, 0 46%, 0% 0%);
}
